<template>
  <div>

    <b-input-group class="mb-2" prepend="🔍">
      <b-form-input
        id="input-search"
        v-model="filter"
        :placeholder="$t('filter')"
        debounce="500"
        class="mr-2"
      ></b-form-input>

      <b-button :disabled="loading" type="submit" variant="secondary" @click="get_issue_list">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        <b-icon v-if="!loading" icon="arrow-clockwise"></b-icon>
        {{ $t("Check for changes") }}
      </b-button>
    </b-input-group>

    <b-table striped hover :fields="table_fields" :items="issues" :busy="loading"
             :filter="filter"
             :filter-included-fields="filterOn"
             :sort-compare="this.sortCompare"
             :sort-by.sync="sortBy"
             sticky-header="70vh"
    >
      <template #cell(url)="row">

        <span v-if="row.item.url !== row.item.service">
          <template v-if="row.item.is_explained">
            <del>
              {{ row.item.url }}
              <br><small>{{ row.item.service }}</small></del>
          </template>
          <template v-else>
            {{ row.item.url }}
            <br><small>{{ row.item.service }}</small>
          </template>
        </span>
      </template>

      <template #cell(impact)="row">
        <span v-if="row.item.impact === 'high'">
          <template v-if="row.item.is_explained">
          ⚪ {{ $t("explained") }}
          </template>
          <template v-else>
            🔴 {{ $t("High") }}
          </template>
        </span>
        <span v-if="row.item.impact === 'medium'">🟠 {{ $t("Medium") }}</span>
        <span v-if="row.item.impact === 'low'">🟢 {{ $t("Low") }}</span>
      </template>
      <template #cell(header)="row">
        {{ row.item.service }}: {{ row.item.header }}
      </template>
      <template #cell(is_explained)="row">
        <span v-if="row.item.is_explained === true">🟢 {{ $t("Yes") }}</span>
        <span v-if="row.item.is_explained === false">⚪ {{ $t("No") }}</span>
      </template>
      <template #cell(last_scan_moment)="row">
        <small>{{ humanize_relative_date(row.item.last_scan_moment) }}, <br>{{
            humanize_date(row.item.last_scan_moment)
          }}</small>
      </template>
      <template #cell(header)="row">
        <template v-if="row.item.is_explained">
          <del>{{ row.item.header }}</del>
        </template><template v-else>
        {{ row.item.header }}
        </template>
        <small v-if="row.item.explanation.length>0"><br>{{ row.item.explanation }}</small>
        <small v-if="row.item.rating"><br>{{ $t("Measured") }}: {{ row.item.rating }}</small>
      </template>
      <template #cell(actions)="row">
        <b-button-group>
          <rescanbutton class="mr-2" :url="row.item.url" :organization="organization.id"></rescanbutton>
          <b-button size="sm" @click="row.toggleDetails">{{ $t("Explain") }}</b-button>
        </b-button-group>
      </template>
      <template #row-details="row">
        <b-card>
          <Explain :scan_id="row.item.id" :scan_type="row.item.type" :row="row"></Explain>
        </b-card>
      </template>
    </b-table>

    <loading :loading="loading"></loading>

    <small v-if="issues.length>0"><i>{{ issues.length }} {{ $t("issues_found") }}</i></small><br><br>

  </div>
</template>

<script>
import Explain from './explain';
import rescanbutton from './../rescanbutton';

export default {
  components: {
    Explain,
    rescanbutton
  },
  props: {
    organization: {
      type: Object
    },
    is_dead: {
      type: Boolean,
      default: false
    },
    not_resolvable: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      // or list_issues_todolist
      default: 'list_issues'
    }

  },

  data: function () {
    return {
      issues: [],
      loading: false,
      sortBy: 'impact',

      filter: "",
      filterOn: ['url', 'impact', 'scan'],
      table_fields: [
        {
          key: 'url',
          sortable: true,
          label: this.$i18n.t('url')
        },
        {
          key: 'impact',
          sortable: true,
          label: this.$i18n.t('impact')
        },
        {
          key: 'is_explained',
          sortable: true,
          label: this.$i18n.t('explained')
        },
        {
          key: 'header',
          sortable: true,
          label: this.$i18n.t('scan')
        },
        {
          key: 'last_scan_moment',
          sortable: true,
          label: this.$i18n.t('last_scan')
        },
        {
          key: 'actions',
          label: this.$i18n.t('actions')
        },
      ],
    }
  },
  mounted: function () {
    if (this.$router.history.current.params.url) {
      this.filter = this.$router.history.current.params.url;
    }
    this.get_issue_list();
  },
  watch: {
    $route: function () {
      if (this.$router.history.current.params.url) {
        this.filter = this.$router.history.current.params.url;
      }
    }
  },
  methods: {
    get_issue_list: function () {
      this.loading = true;
      this.$http.get(`/comply_or_explain/${this.mode}/${this.organization.id}/${this.is_dead ? 1 : 0}/${this.not_resolvable ? 1 : 0}/`).then(data => {
        this.issues = data.data;
        this.loading = false;
      });
    },
    sortCompare(aRow, bRow, key) {
      if (key === 'impact') {
        const choices = {'low': 3, 'medium': 2, 'high': 1}
        let a = choices[aRow[key]]
        let b = choices[bRow[key]]
        if (a === undefined) {
          a = 10
        }
        if (b === undefined) {
          b = 10
        }
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        const a = aRow[key]
        const b = bRow[key]
        return a < b ? -1 : a > b ? 1 : 0
      }
    },
  },
}
</script>
<i18n>
{
  "en": {
    "filter": "Type to filter. For example: 'high example.com'",
    "Check for changes": "Check for changes",
    "Yes": "Yes",
    "No": "No",
    "High": "High",
    "Medium": "Medium",
    "Low": "Low",
    "Measured": "Measured",
    "Explain": "Explain",
    "issues_found": "issues found (without filtering)",
    "url": "Url",
    "impact": "Impact",
    "explained": "Explained",
    "scan": "Scan",
    "last_scan": "Last update",
    "actions": "Actions"
  },
  "nl": {
    "filter": "Typ om te filteren. Bijvoorbeeld: 'high example.com'",
    "Check for changes": "Tabelgegevens vernieuwen",
    "Yes": "Ja",
    "No": "Nee",
    "High": "Hoog",
    "Medium": "Midden",
    "Low": "Laag",
    "Measured": "Gemeten",
    "Explain": "Verklaar",
    "issues_found": "issues gevonden (zonder filteren)",
    "url": "Url",
    "impact": "Impact",
    "explained": "Verklaard",
    "scan": "Meting",
    "last_scan": "Bijgewerkt",
    "actions": "Acties"
  }
}
</i18n>
