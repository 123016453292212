<template>

  <b-modal size="xl" :visible="visible" @hidden="reset(); $emit('close')" header-bg-variant="success"
           header-text-variant="light" scrollable>
    <h3 slot="modal-title">
      <b-icon icon="bicycle" aria-hidden="true"></b-icon>
      {{ $t("title") }}
    </h3>
    <div slot="default" style="margin-bottom: 4em;">

      <server-response :response="server_response" v-if="server_response" :message="$t('' + server_response.message)"/>

      <template v-if="!new_account.password">
        <loading :loading="!organizations_loading"></loading>
        <p>If you need an entirely new organization, please contact us. It requires some more setup.</p>
        <b-form-group
          id="input-group-organization"
          label="Organization:"
          label-for="input-organization"
        >
          <v-select :reduce="organization => organization.code" :state="!!form.organization" v-model="form.organization" :options="organizations_without_accounts_options"></v-select>

        </b-form-group>

        <b-form-group
          id="input-group-username"
          label="Username (min 8 chars):"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="form.username"
            type="text"
            :state="usernameState"
            placeholder="Username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          label="Email adres:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="form.email"
            type="email"
            :state="emailState"
            placeholder="Email address for communication and alerts"
            required
          ></b-form-input>
        </b-form-group>

        <template v-if="!loading">
          <b-button class="defaultbutton modal-default-button" variant="success" @click="add()">
            {{ $t("ok") }}
          </b-button>
        </template>
        <template v-else>
          <b-button disabled="disabled" variant="success" class="defaultbutton modal-default-button">
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
            {{ $t("loading") }}
          </b-button>
        </template>
      </template>

      <template v-if="new_account.password">
        <h3>New account added</h3>
        <p>
          <b>Below credentials will not be shown again. Store it in a safe place.
          </b>
        </p>

        <b-table-simple style="border: 2px dashed red">
          <tr>
            <th>Organization</th>
            <td>{{ new_account.organization_name }}</td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{{ new_account.username }}</td>
          </tr>
          <tr>
            <th>Password</th>
            <td>{{ new_account.password }}</td>
          </tr>
          <tr>
            <th>E-Mail</th>
            <td>{{ form.email }}</td>
          </tr>
        </b-table-simple>
        <div class="container">
          <div class="row">
            <div class="col-md-12 bg-light text-right">
              <b-button @click="reset" class="right" variant="success">Add another account</b-button>
            </div>
          </div>
        </div>


      </template>

    </div>
    <div slot="modal-footer">
      <b-button class="altbutton" @click="reset(); $emit('close')">{{ $t("close") }}</b-button>
    </div>
  </b-modal>

</template>
<script>

export default {
  data: function () {
    return {
      server_response: {},
      loading: false,
      organizations_loading: false,
      organizations_without_accounts: [],
      new_account: {
        username: '',
        password: '',
        organization_name: ''
      },
      form: {
        organization: 0,
        username: "",
        email: "",
      }
    }
  },
  computed: {
    organizations_without_accounts_options() {
      let data = [];
      this.organizations_without_accounts.forEach((item) => {
        data.push({'code': item.id, 'label': `${item.name} (${item.type} in ${item.country})`})
      })
      return data
    },
    emailState() {
      if (this.form.email.length < 10)
        return false;

      if (!this.form.email.includes('@'))
        return false;

      return true;
    },
    usernameState() {
      return this.form.username.length > 7 ? true : false
    }
  },
  mounted: function () {
    this.list_organizations_without_accounts();
  },

  props: {
    visible: {
      type: Boolean
    },
  },

  methods: {
    reset: function () {
      this.form = {
        organization: 0,
        username: "",
        email: "",
      }
      this.new_account = {
        username: '',
        password: '',
        organization_name: ''
      }
      // One organization has been picked.
      this.list_organizations_without_accounts();
    },

    add() {
      this.loading = true;
      this.$http.post(`adminr/add_account/`, this.form).then(data => {
        this.server_response = data.data;
        if (data.data.data) {
          this.new_account = data.data.data;
        }
        // shows the password for the new user only once. Will show a draft e-mail message
        // that can be sent to the user but not via this system.
        this.loading = false;
      });
    },
    list_organizations_without_accounts() {
      this.organizations_loading = true;
      this.$http.post(`adminr/list_organizations_without_accounts/`, this.form).then(data => {
        this.organizations_without_accounts = data.data;
        this.organizations_loading = false;
      });
    },

  },
}
</script>

<i18n>
{
  "en": {
    "title": "Add Account",
    "intro": "Add new accounts that get instant access to this website.",
    "reload_page_warning": "Important: refresh the page after choosing an account!",
    "select": "Select account to use, the account is instantly switched",
    "reload_list": "Reload account list",
    "switched_account": "Switched to account {0}. Refresh the page to use this account.",
    "ok": "Add account"
  },
  "nl": {
    "title": "Account toevoegen",
    "intro": "Voeg een account en gebruiker toe die gebruik kan maken van deze site.",
    "reload_page_warning": "Let op: herlaad de pagina na het wisselen van account!",
    "select": "Selecteer het account om te gebruiken, wisselen gebeurd direct",
    "reload_list": "Lijst met accounts verversen",
    "switched_account": "Geswitched naar account {0}. Ververs de pagina om dit account te gebruiken.",
    "ok": "Account toevoegen"
  }
}
</i18n>
