<template>
  <div>

    <b-input-group class="mb-2" prepend="🔍">
      <b-form-input
        id="input-search"
        v-model="filter"
        :placeholder="$t('Type to filter')"
        debounce="500"
        class="mr-2"
      ></b-form-input>

      <b-button :disabled="loading" type="submit" variant="secondary" @click="get_portfolio_urls" class="mr-2">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        <b-icon v-if="!loading" icon="arrow-clockwise"></b-icon>
        {{ $t("Check for changes") }}
      </b-button>

      <b-button v-if="resolves" @click="add_domains_id = organization; visible.add_domains = true;" variant="success">
        <b-icon icon="plus-square" aria-hidden="true"></b-icon>
        {{ $t("Add urls") }}
      </b-button>
    </b-input-group>

    <b-table striped hover :fields="url_fields" :items="urls" :filter="filter"
             :filter-included-fields="filterOn" :busy="loading" primary-key="id"
             :sort-compare="this.sortCompare" sticky-header="70vh"
    >
      <template #cell(alive)="row">
        <span v-if="row.item.alive === true">🟢 {{ $t("Yes") }}</span>
        <span v-if="row.item.alive === false">🔴 {{ $t("No") }}</span>
      </template>
      <template #cell(impact)="row">
        <router-link :to="{name: 'findings', params: {url: row.item.url}}">
          <span v-if="row.item.impact === 'high'">🔴 {{ $t("High") }}</span>
          <span v-if="row.item.impact === 'medium'">🟠 {{ $t("Medium") }}</span>
          <span v-if="row.item.impact === 'low'">🟢 {{ $t("Low") }}</span>
          <span v-if="row.item.impact === 'ok'">⚪ {{ $t("None") }}</span>
        </router-link>
      </template>
      <template #cell(planned_scans)="row">
        <!-- {{row}} -->
        <template v-for="planned_scan in row.item.planned_scans">
          <b-icon v-if="planned_scan.scanner === 'tls_qualys'" icon="lightning" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'internet_nl_mail'" icon="envelope" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'internet_nl_web'" icon="globe" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'security_headers'" icon="tag" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'plain_http'" icon="unlock" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'dnssec'" icon="tags" :key="planned_scan.id" aria-hidden="true"
                  v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'ftp'" icon="server" :key="planned_scan.id" aria-hidden="true"
                  v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'dns_clean_wildcards'" icon="octagon" :key="planned_scan.id"
                  aria-hidden="true" v-b-tooltip.hover
                  :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`">
            {{ planned_scan.scanner }}
          </b-icon>
          <b-icon v-if="planned_scan.scanner === 'nuclei_full'" icon="bug" :key="planned_scan.id"
            aria-hidden="true" v-b-tooltip.hover
            :title="`${planned_scan.scanner} since ${humanize_relative_date(planned_scan.requested_at_when)}`"
          >
            {{ planned_scan.scanner }}
          </b-icon>
        </template>
      </template>
      <template #cell(actions)="row">
        <b-button-group>
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            <b-icon icon="clock-history"></b-icon>
            {{ $t("History") }}
          </b-button>
          <rescanbutton class="mr-2" v-if="row.item.resolves" :url="row.item.url"
                        :organization="organization.id"></rescanbutton>
          <fullscanbutton class="mr-2" :url="row.item.url" :organization="organization.id" />
        </b-button-group>
      </template>
      <template #row-details="row">
        <b-card>
          <b-button size="sm" class="mb-2" @click="row.toggleDetails">Close History</b-button>
          <timeline class="m-2" :organization="organization" :url="row.item.url"></timeline>
          <b-button size="sm" class="mt-2" @click="row.toggleDetails">Close History</b-button>
        </b-card>
      </template>
    </b-table>

    <small v-if="urls.length>0"><i>{{ urls.length }} {{ $t("urls in this list (without filtering)") }}</i></small><br><br>

    <loading :loading="loading"></loading>

    <AddDomains :list="organization" :show="visible.add_domains" :visible="visible.add_domains"
                @cancel="visible.add_domains = false" @added="get_portfolio_urls()" class="right"></AddDomains>

    <PlanFullScanDialog />
  </div>
</template>

<script>
import AddDomains from './add domains';
import timeline from './timeline';
import rescanbutton from './../rescanbutton';
import Fullscanbutton from "@/components/fullscanbutton";
import PlanFullScanDialog from "@/components/fullscopetests/PlanFullScopeDialog";


export default {
  components: {
    PlanFullScanDialog,
    Fullscanbutton,
    AddDomains,
    timeline,
    rescanbutton
  },

  props: {
    organization: {
      type: Object
    },
    resolves: {
      type: Number
    }
  },

  data: function () {
    return {
      visible: {
        add_domains: false,

      },
      add_domains_id: {},

      timelines: [],
      urls: [],

      filter: "",
      filterOn: ["url"],
      loading: false,

      url_fields: [
        {
          key: 'url',
          sortable: true,
        },
        {
          key: 'alive',
          sortable: true,
          label: this.$i18n.t('alive')
        },
        {
          key: 'impact',
          sortable: true,
          label: this.$i18n.t('impact')
        },
        {
          key: 'planned_scans',
          sortable: true,
          label: this.$i18n.t('planned_scans')
        },
        {
          key: 'actions',
          label: this.$i18n.t('actions')
        },
      ],
    }
  },
  mounted: function () {
    this.get_portfolio_urls();
  },
  methods: {
    get_portfolio_urls: function () {
      this.loading = true;
      this.server_response = {};
      this.$http.get(`/portfolio/list_urls/${this.organization.id}/${this.resolves}/`).then(data => {
        this.urls = data.data;
        this.loading = false;
      });
    },
    sortCompare(aRow, bRow, key) {
      if (key === 'impact') {
        const choices = {'low': 3, 'medium': 2, 'high': 1}
        let a = choices[aRow[key]]
        let b = choices[bRow[key]]
        if (a === undefined) {
          a = 10
        }
        if (b === undefined) {
          b = 10
        }
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        const a = aRow[key]
        const b = bRow[key]
        return a < b ? -1 : a > b ? 1 : 0
      }
    },
  },
}
</script>
<i18n>
{
  "en": {
    "Check for changes": "Check for changes",
    "Add urls": "Add urls",
    "Yes": "Yes",
    "No": "No",
    "High": "High",
    "Medium": "Medium",
    "Low": "Low",
    "None": "None",
    "History": "History",
    "urls in this list (without filtering)": "urls in this list (without filtering)",
    "alive": "In Report",
    "impact": "Impact",
    "planned_scans": "Planned Scans",
    "actions": "Actions",
    "Type to filter": "Type to filter"
  },
  "nl": {
    "Check for changes": "Tabelgegevens vernieuwen",
    "Add urls": "Adressen toevoegen",
    "Yes": "Ja",
    "No": "Nee",
    "High": "Hoog",
    "Medium": "Midden",
    "Low": "Laag",
    "None": "Geen",
    "History": "Geschiedenis",
    "urls in this list (without filtering)": "adressen in deze lijst (zonder filter)",
    "alive": "In Rapport",
    "impact": "Impact",
    "planned_scans": "Geplande scans",
    "actions": "Acties",
    "Type to filter": "Typ hier om te filteren"
  }
}

</i18n>
