<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>

    <template v-if="show_retry">
      <b-button size="sm" class="m-2" type="submit" variant="secondary" @click="get_portfolio_organizations">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        Retry retrieval
      </b-button>
    </template>

    <b-button v-b-toggle.collapse-1 variant="primary">
      <b-icon icon="chevron-expand"></b-icon>
      {{ $t("Guidelines") }}
    </b-button>
    <b-collapse id="collapse-1" class="mt-2">

      <h2>Trust in your explanations</h2>
      <p>Explanation are public explanations which are intended to increase trust in your organizations capability to
        provide
        secure and sensible services. To strengthen this trust, make sure your explanations are great and fit the
        intention of your organization. In any case it is better to <b>not</b> add an explanation instead of a bad one!
      </p>

      <h4>Good explanations</h4>
      <p>Good explanations show design decisions. They are breaks from the norms for various reasons.</p>
      <p>
        Following technical recommendations:
        <b-alert variant="success" show>HTTPS is disabled because Certificate Revocation Lists must be accessed without
          encryption. CRL data contains checksums to validate integrity.
        </b-alert>
        Understanding the target audience:
        <b-alert variant="success" show>This service is intended for devices, these devices trust this specific
          certificate.
        </b-alert>
        Understanding your platform:
        <b-alert variant="success" show>
          This service is intended for devices, which do not use these features at all.
        </b-alert>
        Understanding your users:
        <b-alert variant="success" show>
          Encryption cannot be enabled because Commodore 64's do not speak TLS but still need to access these pages.
        </b-alert>

      </p>

      <h4>Bad explanations</h4>
      <p>In short: it's better to use no explanation instead of a bad one.</p>
      <p>Bad explanation will reflect badly on your organization and are generally seen as unprofessional and
        thus unfavorable.
        They generally show, in a roundabout way, that you're not in control of your own infrastructure.
        It's best to never use explanations like the following.</p>
      <p>Using bad explanations can lead to a revocation of the explanation feature, as well as bad reputation.</p>
      <p>
        Keeping the situation vague / meaningless promises:
        <b-alert variant="danger" show>We're working on it. / Supplier is working on it. / Will be fixed.</b-alert>

        Deflecting responsibily / shifting blame:
        <b-alert variant="danger" show>The supplier will not fix it unless we pay a lot of money.</b-alert>

        Denial:
        <b-alert variant="danger" show>We don't own this service / it is not in our scope.</b-alert>
      </p>

      <h4>Automated explanations</h4>
      <p>Certain issues are explained automatically. For example certain Microsoft services and certain TLS certificates
        that are not trusted by design.</p>
      <h4>Canned explanations</h4>
      <p>There are various canned explanations which are automatically translated on the website.</p>
    </b-collapse>
    <br><br>

    <b-tabs content-class="mt-3">
      <template v-for="organization in organizations">
        <b-tab :title="organization.name" :key="organization.id" lazy>

          <b-tabs>
            <b-tab lazy>
              <template #title>
                <b-icon icon="card-checklist" variant="success"></b-icon>
                {{ $t("todo") }}
              </template>
              <issue_table :organization="organization" :is_dead="false" :not_resolvable="false" mode="list_issues_todolist"/>
            </b-tab>

            <b-tab lazy>

              <template #title>
                <b-icon icon="hdd-network" variant="success"></b-icon>
                {{ $t("resolving") }}
              </template>

              <b-tabs>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="check" variant="success"></b-icon>
                    {{ $t("not_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="false" :not_resolvable="false"/>
                </b-tab>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="moon" variant="secondary"></b-icon>
                    {{ $t("is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="true" :not_resolvable="false"/>
                </b-tab>
              </b-tabs>


            </b-tab>
            <b-tab lazy>
              <template #title>
                <b-icon icon="wifi-off" variant="danger"></b-icon>
                {{ $t("not_resolving") }}
              </template>

              <b-tabs>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="check" variant="secondary"></b-icon>
                    {{ $t("archived_not_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="false" :not_resolvable="true"/>
                </b-tab>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="moon" variant="secondary"></b-icon>
                    {{ $t("archived_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="true" :not_resolvable="true"/>
                </b-tab>
              </b-tabs>

            </b-tab>


          </b-tabs>

        </b-tab>
      </template>
    </b-tabs>

  </div>
</template>

<script>

import issue_table from "@/components/issues/issue_table";

export default {
  components: {
    issue_table
  },
  data: function () {
    return {
      organizations: [],
      loading: false,
      show_retry: false,
    }
  },
  mounted: function () {
    this.get_portfolio_organizations();
  },
  methods: {
    get_portfolio_organizations: function () {
      this.loading = true;
      this.server_response = {};
      this.$http.get('/portfolio/list_organizations/').then(data => {
        this.organizations = data.data;
        this.loading = false;
        this.show_retry = false;
      }).catch(() => {
        this.show_retry = true;
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Findings",
    "intro": "Below are all current findings with an impact (aka 'known issues'). Findings disappear automatically when they are rescanned and fixed. Persistent issues that contrast a design decision can be explained publicly.",
    "Guidelines": "Use this manual to provide great explanations",
    "resolving": "Resolving Domains",
    "not_resolving": "Not Resolving Domains",
    "not_is_dead": "Alive and in report",
    "is_dead": "(temporary) Archived findings",
    "archived_not_is_dead": "In report when the domain resolves",
    "archived_is_dead": "(temporary) Archived findings even if domain resolves",
    "todo": "To Do List"
  },
  "nl": {
    "title": "Bevindingen",
    "intro": "Onderstaand overzicht toont alle gemeten (beveiligings)problemen van het portfolio. Wanneer deze zijn opgelost en herscand worden ze automatisch uit de lijst gehaald. Wanneer iets 'by design' afwijkt van een norm, kan hier publiek uitleg over worden gegegeven.",
    "Guidelines": "Gebruik deze handleiding om goede verklaringen te geven (Engels)",
    "resolving": "Bereikbare domeinen",
    "not_resolving": "Niet bereikbare domeinen",
    "not_is_dead": "Bevindingen in rapport",
    "is_dead": "(tijdelijk) Gearchiveerde bevindingen",
    "archived_not_is_dead": "In rapport wanneer het domein bereikbaar wordt",
    "archived_is_dead": "(tijdelijk) Gearchiveerde bevindingen zelfs wanneer het domein bereikbaar wordt",
    "todo": "Nog Openstaand"
  }
}
</i18n>
